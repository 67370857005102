body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --color-grisOscuro: #666;
  /* --color-naranja: #ffa500; */
  --color-naranja: #ca2228;
  --color-azul: #02182b;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.full {
  height: 100vh;
  background-color: red;
  padding-top: 100px;
  background: url(/src/assets/backlogin.jpg);
  background-repeat: no-repeat;
  background-size: auto;
}

.logoContainer {
  padding-left: 270px;
  margin-bottom: 15px;
}

.logoLogin {
  height: 60px;
}

.loginCuadro {
  width: 350px;
  background-color: rgba(7, 60, 85, 0.5);
  padding: 20px 50px;
  border-radius: 50px;
  margin-left: 200px;
}

.tituloLogin {
  text-align: center;

  color: #f1f1f1;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.logo {
  height: 40px;
}

.botonCard {
  color: #ca2228;
  margin-top: 2px;
  float: right;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
  padding: 3px 5px;
}

.botonCard2 {
  color: #ca2228;
  width: 80px;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  background-color: #fff;
  height: 31px;
  line-height: 31px;
  opacity: 0.5;
}

.botonCardL {
  color: #ca2228;

  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  background-color: #fff;
  height: 31px;
  line-height: 31px;
  opacity: 0.5;
}

.botonCardLeft {
  color: #ca2228;
  margin-top: 2px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.botonCardLeft2 {
  color: #ca2228;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.TotalRegistros {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #ca2228;
  width: 150px;
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
}

.botonCard:hover,
.botonCardL:hover,
.botonCard2:hover,
.botonCardLeft:hover {
  border-radius: 5px;
  background-color: #ddd;
  opacity: 1;
}

.card__titulo {
  margin-left: 15px;
  margin-bottom: 5px;
  color: var(--color-naranja);
  font-weight: 500;
  font-size: 20px;
}

.card__accion {
  text-align: right;
  padding-bottom: 5px;
  padding-right: 15px;
}

.card__contenido {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ddd;
  border-radius: 5px;
}

.card__contenido_sin {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.renglonTabla {
  font-size: 12px;
}
.cajaHistorial {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
  margin-top: 10px;
}

.tabla {
  cursor: pointer;
}

.label {
  font-weight: 600;
  margin-left: 5px;
  color: var(--color-azul);
}

.valor {
  padding: 2px 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
  min-height: 26px;
}

.botonIcono {
  color: #ca2228;
  opacity: 0.4;
  cursor: pointer;
}

.botonIcono:hover {
  opacity: 1;
}

.rowItemElemento {
  padding: 10px 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  border-radius: 5px;
}

.bola {
  height: 25px;
  width: 25px;
  padding-top: 4px;
  color: #fff;
  margin: auto;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin-right: 5px;
}

.bolaRoja {
  background-color: #ca2228;
}

.bolaNaranja {
  background-color: orange;
}

.bolaVerde {
  background-color: green;
}

.tituloColumna {
  background-color: #e0e0e0;
  font-weight: 600;
  text-align: center;
  padding: 5px;
  border: 1px solid #ccc;
  color: #999;
}

.rowHeader {

}

.cajaMensaje {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 2px;
  
  }
.tituloModal {
  font-weight: 600;
  font-size: 20px;
  color: Teal;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;

}
.rowItem {
  padding: 15px 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  cursor: pointer;

  background: url(/src/assets/chevron-right.png) no-repeat;
  background-size: 20px 20px;
  background-position: right 10px bottom 15px;
  border-radius: 5px;
  margin: 0px 0;
}

.rowItem:hover,
.rowItemElemento:hover {
  background-color: #f1f1f1;
}

.≈ {
  color: #ca2228;
  font-weight: 600;
  text-align: center;
}

.subTitulo {
  color: #ca2228;
  font-weight: 600;
}

.circulo {
  width: 20px;
  background-color: #ca2228;
  color: #fff;
  margin: auto;
  border-radius: 50%;
  font-size: 12px;
}
